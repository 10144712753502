import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import Sarang from './Sarang_homepage';
import Sarang_korean from './Sarang_korean_version';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Sarang_korean />
  </React.StrictMode>
);

