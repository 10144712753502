import React from "react";
import GoogleMapReact from 'google-map-react';
import './gmap.css';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function SimpleMap(){
  const defaultProps = {
    center: {
      lat: 47.313494,
      lng: -122.33931
    },
    zoom: 30
  };

  return (

    // also install this in terminal npm install --save google-map-react
    // this is the website used to make the google map https://www.maps.ie/create-google-map/

    <div className="iframe">
        <iframe id='iframe'  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Sarang%20AFH+(Sarang%20AFH)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
            <a href="https://www.maps.ie/population/">Calculate population in area</a>
        </iframe>
    </div>
  );
}


