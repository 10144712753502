import React from 'react';
import './Sarang_homepage.css';
import Care from './images/care.webp';
import Logo from './images/Front Logo.png';
// import Bed from './images/bed.webp';
import Walk from './images/walk.webp';
import Exercise from './images/exercise.webp';
import Ocean from './images/ocean.webp';
import Food from './images/food.webp';
import Group from './images/group.webp';
import Group2 from './images/goup2.webp';
import Logo_small from './images/Logo_small.png';
import Logo_with_text from './images/Logo with text.png';
import Gmap from './components/gmap';
import { AiFillPhone,AiFillMail } from "react-icons/ai";
import { AiOutlineGlobal } from "react-icons/ai";

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Bed from './images/bed.webp';

import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
// make sure to install emailjs browser by doing npm i '@emailjs/browser'

import Header_Paragraph from './components/header_paragraph';
import Picture_Paragraph from './components/picture_paragraph';
import Picture_Paragraph_Reverse from './components/picture_paragraph_reverse';

import Box from '@mui/joy/Box';
import Fab from '@mui/material/Fab';
// npm install @mui/material @emotion/react @emotion/styled
// npm install @mui/material @mui/styled-engine-sc styled-components
// npm install @mui/joy @emotion/react @emotion/styled

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));


function Sarang_homepage() {
  
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (e) => {
        e.preventDefault();
        setOpen(true);
    };
    const handleClose = (e) => {
        e.preventDefault();
        setOpen(false);
        window.location.reload();
    };

    const [kor, setKor] = useState('change_language_none');
    const [eng, setEng] = useState('change_language_contents');
    const [button, setButton] = useState('한글');

    function changeToKorean(e) {
        e.preventDefault();
        setKor('한글');
    }

    function changeButton(e) {
        e.preventDefault();
        if (button == '한글') {
            setButton('Eng');
            setKor('change_language_contents');
            setEng('change_language_none');
            
        } else {
            setButton('한글')
            setEng('change_language_contents');
            setKor('change_language_none');
        };
    };

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        
        emailjs.sendForm('service_qrxmau9', 'template_4lsdmyn', form.current, 'bnXU9bTRfU5Ha9b48')

        .then((result) => {
            setOpen(true);
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
    <body>
        {/* ------------------------ language change button -------------------------------- */}
        
        {/* ------------------------ language change button -------------------------------- */}

        <div className='header'>
            <div className='logo' data-aos="fade-in" data-aos-duration="2000">
                <div className='logo2'>
                    <img id='logo' src={Logo_small} alt="" />
                    <img id='logo2' src={Logo} alt="" />
                </div>
                {/* <a href="#logo_footer"><button id="contact_button">Contact Us</button></a> */}
                <div>
                    <Fab id='change-lang' color="primary" aria-label="add" onClick={changeButton}>
                    {/* <button id='change-lang' onClick={changeButton}> */}
                        <h3>
                        <div className='change-lang'>
                            <AiOutlineGlobal />{button}
                        </div>
                        </h3>
                    </Fab>
                    {/* </button> */}
                </div>
            </div>
            <div className='hero-logo' data-aos="fade-in" data-aos-duration="2100">
                <h1 className='hero-text' id={eng}>A Place Where Your Family Is Loved</h1>
                <h3 className='hero-text' id={kor}>가족이 사랑받는 곳</h3>
                <a href="#contact-form"><button><h3 id={eng}>Contact Us</h3><h3 id={kor}>문의하기</h3></button></a>
            </div>
        </div>

        {/* ------------------------- info section ------------------------- */}
        <div className='picture_paragraph_container'>
            <div className='picture'>
                <img src={Bed} alt="bed with picture above frame" />
            </div>
            <div className='picture_paragraph2'>
                <div data-aos="fade-in" data-aos-duration="2300">
                    <h1 id={eng}>For You Or Your Loved One</h1>
                    <h1 id={kor}>당신이나 당신의 사랑하는 사람을 위해</h1>
                </div>
                {/* <br /> */}
                <div data-aos="fade-in" data-aos-duration="2300">
                    <h3 id={eng}>Our facility offers spacious, comfortable living areas designed to feel like home. Each resident enjoys a private or semi-private room that is tastefully furnished, ensuring a sense of personal space and privacy. Common areas are thoughtfully designed to encourage social interaction, with cozy lounges, dining areas, and beautifully landscaped outdoor spaces.</h3>
                    <h3 id={kor}>우리 시설은 집처럼 느껴지도록 설계된 넓고 편안한 생활 공간을 제공합니다. 각 거주자는 개인 공간과 프라이버시를 보장하면서 세련되게 가구가 비치된 개인실 또는 반개인실을 즐깁니다. 공용 공간은 아늑한 라운지, 식사 공간, 아름답게 조경된 야외 공간 등 사회적 상호 작용을 장려하도록 세심하게 설계되었습니다.</h3>
                </div>
                <a href="#contact-form" data-aos="fade-in" data-aos-duration="2300"><button><h3 id={eng}>(2) Beds Available</h3><h3 id={kor}>(2) 이용 가능한 침대</h3></button></a>
                
            </div>
        </div>
        <div className='picture_paragraph_container2'>
            <div className='picture_paragraph2'>
                <div data-aos="fade-in" data-aos-duration="2300">
                    <h1 id={eng}>Join Our Team</h1>
                    <h1 id={kor}>우리 팀에 합류하세요</h1>
                </div>
                {/* <br /> */}
                <div data-aos="fade-in" data-aos-duration="2300">
                    <h3 id={eng}>At Sarang AFH, we are always looking for compassionate and dedicated individuals to join our team. If you have a passion for helping others and a commitment to providing exceptional care, we invite you to consider a career with us. Our team members are the heart of our organization, and we value their contributions immensely.</h3>
                    <h3 id={kor}>Sarang AFH에서는 항상 우리 팀에 합류할 자비롭고 헌신적인 개인을 찾고 있습니다. 다른 사람을 돕는 데 대한 열정과 탁월한 진료 제공에 대한 헌신이 있다면, 우리와 함께 일할 것을 고려해 보시기 바랍니다. 우리 팀원은 우리 조직의 핵심이며, 우리는 그들의 기여를 매우 소중하게 생각합니다.</h3>
                </div>
                <a href="#contact-form" data-aos="fade-in" data-aos-duration="2300"><button><h3 id={eng}>(1) Korean Speaking Caretaker</h3><h3 id={kor}>(1) 한국어를 구사하는 관리인</h3></button></a>
                
            </div>
            <div className='picture2'>
                <img src={Care} alt="caretaker feeding a resident soup" />
            </div>
        </div>
        {/* ------------------------- info section ------------------------- */}


        <div className='header_paragraph_container' data-aos="fade-in" data-aos-duration="2300">
            <div className='ourmission'>
                <h1 id={eng}>Our Mission</h1>
                <h1 id={kor}>우리의 미션</h1>
            </div>
            <div className='ourmission-paragraph'>
                <h3 id={eng}>"We are a dedicated collective committed to enhancing the long-term health care experience by delivering exceptional support. Our mission is to positively impact lives by providing unparalleled care, fostering an environment of well-being, and ensuring the utmost quality of life for all individuals.”</h3>
                <h3 id={kor}>"우리는 헌신적인 공동체로서 우수한 케어를 제공하여 장기 건강 관리 환경을 향상시키는 것을 목표로 합니다. 우리의 사명은 최고의 케어를 제공함으로써 긍정적인 영향을 미치고, 안녕한 환경을 조성하며, 장기간 케어 환경 내 모든 개인들의 삶의 질을 보장하는 것입니다."</h3>
            </div>
        </div>

        <div className='services-container'>
            <div className='services' data-aos="fade-in" data-aos-duration="2300">
                <div className='mission_statement_header'>
                    <h1 id={eng}>Services</h1>
                    <h1 id={kor}>서비스</h1>
                </div>
                <br />
                <div className='mission_statement_paragraph' data-aos="fade-in" data-aos-duration="2300">
                    <h3 className='mission_statement' id={eng}>We provide the best food, comfort, and social atmosphere that your family deserves!</h3>
                    <h3 className='mission_statement' id={kor}>우리는 귀하의 가족이 누려야 할 최고의 음식, 편안함, 사회적 분위기를 제공합니다!</h3>
                </div>
            </div>
            <div className='parent_photo_grid' data-aos="fade-in" data-aos-duration="2300">
                <div className='photo_grid'>
                    <img id="photo_grid" src={Group2} alt="" />
                    <img id="photo_grid" src={Walk} alt="" />
                    <img id="photo_grid" src={Exercise} alt="" />
                    <img id="photo_grid" src={Ocean} alt="" />
                    <img id="photo_grid" src={Food} alt="" />
                    <img id="photo_grid" src={Group} alt="" />
                </div>
            </div>
        </div>

        <div className='contact-form' id='contact-form'>
            <div className='contact-image'>
                <div className='make-column'>
                    <div data-aos="fade-in" data-aos-duration="2300">
                        <h1 className='about-header' id={eng}>Contact</h1>
                        <h1 className='about-header' id={kor}>문의하기</h1>
                    </div>
                    <div data-aos="fade-in" data-aos-duration="2300">
                        <h3 id={eng}>Feel free to contact us with any questions.</h3>
                        <h3 id={kor}>질문이 있으시면 언제든지 문의해 주세요.</h3>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <div className='footer-info' data-aos="fade-in" data-aos-duration="2300">
                    <AiFillPhone/>
                    <a href='tel:2532373165' className='footer-phone' id={eng}>Phone: 253-237-3165</a>
                    <a href='tel:2532373165' className='footer-phone' id={kor}>전화: 253-237-3165</a>
                    
                </div>
                <br />
                <div className='footer-info' data-aos="fade-in" data-aos-duration="2300">
                    <AiFillMail/>
                    <a href="mailto:info@sarangafh.com" className='footer-phone' id={eng}>Email: info@sarangafh.com</a>
                    <a href="mailto:info@sarangafh.com" className='footer-phone' id={kor}>이메일: info@sarangafh.com</a>
                </div>
            </div>
            <form ref={form} onSubmit={sendEmail}>
                <div className='contact-page'>
                    <div className='name'>
                        <div className='make-column' data-aos="fade-in" data-aos-duration="2300">
                            <h1 className='contact-input' id={eng}>First Name</h1>
                            <h1 className='contact-input' id={kor}>이름</h1>
                            <div className='input-boxes'>
                            <input name='firstname' type="text" />
                            </div>
                        </div>
                        <div className='make-column' data-aos="fade-in" data-aos-duration="2300">
                            <h1 className='contact-input' id={eng}>Last Name</h1>
                            <div className='input-boxes' id={eng}>
                            <input name='lastname' type="text" />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='make-column' data-aos="fade-in" data-aos-duration="2300">
                        <h1 className='contact-input' id={eng}>Email</h1>
                        <h1 className='contact-input' id={kor}>이메일</h1>
                        
                        <input id='contact-input3' name='email' type="text" />
                    </div>            
                    <br />
                    <div className='make-column' data-aos="fade-in" data-aos-duration="2300">
                        <h1 className='contact-input' id={eng}>Message</h1>
                        <h1 className='contact-input' id={kor}>메시지</h1>
                        <textarea id='contact-input2' maxlength="300" rows='4' type="text" name='message' label="Message" />
                    </div>
                    <br />
                    
                    <React.Fragment>
                        {/* <Button variant="outlined" onClick={handleClickOpen}>
                            Open dialog
                        </Button> */}
                        <div data-aos="fade-in" data-aos-duration="2300">
                            <button type="submit"><h3 id={eng}>Submit</h3><h3 id={kor}>제출</h3></button>
                        </div>
                        <BootstrapDialog
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                        >
                            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                            <h1 id={eng}>Thank you!</h1>
                            <h1 id={kor}>감사합니다!</h1>
                            </DialogTitle>
                            <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            >
                            <CloseIcon />
                            </IconButton>
                            <DialogContent dividers>
                            <Typography gutterBottom>
                                <h3 id={eng}>We will get back to you as soon as possible.</h3>
                                <h3 id={kor}>최대한 빨리 연락드리겠습니다.</h3>
                            </Typography>
                            </DialogContent>
                            {/* <DialogActions>
                            <Button autoFocus onClick={handleClose}>
                                Save changes
                            </Button>
                            </DialogActions> */}
                        </BootstrapDialog>
                    </React.Fragment>
                </div>
            </form>
        </div>

        <div className='contact'>
            <div className='item1' data-aos="fade-in" data-aos-duration="2300">
                <img id="logo_footer" src={Logo_with_text} alt="" />
                <br />
                <a>34836 14th Pl SW</a>
                <a>Federal Way, WA 98023</a>
            </div>
            {/* <div className='item2 footer-info-parent'>
                <div className='footer-info'>
                    <AiFillPhone/>
                    <a href='tel:2532373165' id='footer-phone'>Phone: 253-237-3165</a>
                </div>
                <div className='footer-info'>
                    <AiFillMail/>
                    <a href="mailto:info@sarangafh.com" id='footer-phone'>Email: info@sarangafh.com</a>
                </div>
            </div> */}
                <Gmap data-aos="fade-in" data-aos-duration="2300"/>

        </div>
    </body>
  )
}

export default Sarang_homepage;